import React, {useEffect} from 'react'

const Team = () => {

useEffect(()=>{
document.title = 'Our Team | Bundikaghoro Cocoa Farmers’ Cooperative Society Limited';

},[]);

  return (
    
    <section className='section-cover team-cover' >
         <div className='content-container'>
           <h2 className="home-h2 team-h2">Our Team</h2>
           <div className="new-team-cover">
                   <article className="new-team-member">
                       <img src="./images/Francis.jpg" className='new-team-member-photo'></img>
                        <h3>Mr. Bukama Francis</h3>
                        <p>Chairperson</p>
                   </article>
                   <article className="new-team-member">
                       <img src="./images/15.jpeg" className='new-team-member-photo'></img>
                       <h3>Biira Katusabe Racheal</h3>
                       <p>Manager</p>
                   </article>
                    <article className="new-team-member">
                       <img src="./images/John.jpeg" className='new-team-member-photo'></img>
                       <h3>Mr. Kabaseke John</h3>
                       <p>Vice Chairperson</p>
                   </article>
                   <article className="new-team-member">
                       <img src="./images/Lydia.jpeg" className='new-team-member-photo'></img>
                       <h3>Lydia</h3>
                       <p>Field Officer</p>
                   </article>
                    
                   <article className="new-team-member">
                       <img src="./images/14.jpeg" className='new-team-member-photo'></img>
                       <h3>Kemigisha Judith</h3>
                       <p>Accounts Assistant</p>
                   </article>


           </div>


           </div>
</section>
  )
}

export default Team