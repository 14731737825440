import React from "react";

import Carousel from 'react-bootstrap/Carousel';

function CarouselFade() {
  return (
    <Carousel fade className="home-main-slides">
      <Carousel.Item>
        <img
          className="d-block w-100"
          // src="./images/1.jpg"
          src="./images/1a.jpeg"
          alt="First slide"
        />
        <Carousel.Caption>
          {/* <h3 className="slide-h3">Wanway Solutions Ltd</h3> */}
          <p className="slide-p slide-p1">Bundikaghoro Cocoa Farmers’ Cooperative Society Limited</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          // src="./images/43.jpeg"
          src="./images/2a.jpeg"
          alt="Second slide"
        />

        <Carousel.Caption>
          {/* <h3>We're Prominent In Engineering Works</h3> */}
          <p className="slide-p slide-p2"> Quality Our Priority</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          // src="./images/53.jpeg"
            src="./images/3a.jpeg"
          alt="Third slide"
        />

        <Carousel.Caption>
          {/* <h3>We Plan, Schedule, Execute & Control Projects
          </h3> */}
          <p className="slide-p slide-p4">
           Addressing problems facing the cocoa sector 
          </p>
        </Carousel.Caption>

      </Carousel.Item>

      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/7a.jpeg"
          alt="Third slide"
        />

          <Carousel.Caption>
          {/* <h3>We Plan, Schedule, Execute & Control Projects
          </h3> */}
          <p className="slide-p slide-p4">
        Providing better market access
          </p>
        </Carousel.Caption>
        
      </Carousel.Item>
      
    </Carousel>
  );
}

export default CarouselFade;