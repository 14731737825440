import React, {useEffect} from 'react';
import WareHouse from '../components/WareHouse';
import CommercialBlock from '../components/CommercialBlock';
import Healthcenter from '../components/Healthcenter';
import TH from '../components/TH';
import ClassroomBlocks from '../components/ClassRoomBlocks';
import WaterSupply from '../components/WaterSupply';
import Hardware from '../components/Hardware';
import Residential1 from '../components/Residential1';
import Residential2 from '../components/Residential2';
import Residential3 from '../components/Residential3';
import Residential4 from '../components/Residential4';
import Residential5 from '../components/Residential5';
import Roads1 from '../components/Roads1';
import Land1 from '../components/Land1';
import Land2 from '../components/Land2';
import Farming from '../components/Farming';
import Bridge1 from '../components/Bridge1';
import Aluminium1 from '../components/Aluminium1';
import Commercial1 from '../components/Commercial1';
import Roads2 from '../components/Roads2';
import Excavation from '../components/Excavation';
import Warehouse1 from '../components/Warehouse1';
import Aswa1 from '../components/Aswa1';

const Gallery = () => {
  useEffect(()=>{
document.title = 'Gallery | Bundikaghoro Cocoa Farmers’ Cooperative Society Limited';

},[]);
  return (
    <section className='section-cover gallery-cover' >
       <h2 className="home-h2 gallery-h2">Gallery</h2>
      <div className='content-container gallery gallery1 gallery2'>
        <article className='gallery-content'>
          {/* <img src='./images/37.jpg' className='gallery-image' alt='image'></img> */}
           <Residential5></Residential5>
         </article>
        
        
         
      </div>

    </section>
  )
}

export default Gallery