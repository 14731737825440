import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import PowerCarousel from '../components/PowerCarousel';
import TelecomCarousel from '../components/TelecomCarousel';
import RoadCarousel from '../components/RoadCarousel';
import SupportCarousel from '../components/SupportCarousel';
import MaintCarousel from '../components/Maintenance';
import EmergencyCarousel from '../components/Emergency';
import BuildingsCarousel from '../components/BuildingsCarousel';
import Plumbing from '../components/plumbing';
import AC from '../components/AC';
import Painting from '../components/Painting';
import Doors from '../components/Doors';
import Al from '../components/Al';
import Carpentry from '../components/Carpentry';
import Tile from '../components/Tile';
import Cleaning from '../components/Cleaning';
import Supply from '../components/Supply';
import GS from '../components/GS';
let scrollFunc = () =>{
window.scrollTo({
   top:0,
behavior: "smooth",
});

}


const Services = () => {

useEffect(()=>{
document.title = 'Our Services | Bundikaghoro Cocoa Farmers’ Cooperative Society Limited';

},[]);


  return (
    <>
     <section className='home-quote' style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.8)), url("./images/2a.jpeg")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundAttachment:'fixed',
    padding:'8rem 0'

    }}>
          <div className='quote-content'>
            <h2 className='quote-h3 quote-h3-a'>What We Do</h2>
            
        </div>
    </section>
    <section className='section-cover services-cover' >
      {/* <h2 className="home-h2 services-h2">Our Services</h2> */}
           <div className='content-container services-container'>
              <div className='service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Climate smart agricultural practices </h3>                    
<ul>
  <li>Training cocoa farmers on climate smart agricultural practices.</li>
  <li>Monitoring and supporting farmer adaption of climate smart agricultural practices.</li>
</ul>

 
                  {/* <Link to='#' className='home-link'>See Details</Link> */}
                  </article>

                  <article className='service-slides'>
                    <RoadCarousel></RoadCarousel>
                 
                  </article>     
            </div>

            <div className='service-container odd-service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Intercropped cocoa (cash crop) tree plants/gardens with food crops
</h3>
<ul>
<li>Sensitizing and training cocoa farmers to intercrop cocoa trees with cassava.</li>
<li>Monitoring performance of farmers uptake of intercropping cocoa and cassava.</li>
</ul>
{/* <p className='service-desc'></p> */}
                  </article>

                  <article className='service-slides'>
                    <SupportCarousel></SupportCarousel>
                 
                  </article>     
            </div>

            <div className='service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Sensitizing, enrolling and training cocoa farmers about organic and fair trade standards with emphasis on quality improvement; fermentation with specific protocols</h3>
                       {/* <p><strong>Beda Civil Works & Contractors Limited</strong> continues its success by offering an unparalleled level of diversity and experience in Construction and Maintenance of Road Infrastructure. </p>                 
                <p> We have the capacity and skills to undertake any road construction or repair project. We have excellent expertise in Structural & Geometric Road Design, Road & Highway Construction, Surface Dressing, Asphalt and concrete paving, Pothole Patching, General Road and Highway Maintenance and Road Marking & Signs.</p> */}
           {/* <ul>
           <li><strong>Client:</strong> Musana Empowerment Centre</li> 
<li><strong>Location:</strong> Iganga District</li>
<li><strong>Period:</strong> December 2022 - March 2023</li>
<li><strong>Value:</strong> Ugx 400,000,000</li>
</ul> */}
                  </article>

                  <article className='service-slides'>
                    <MaintCarousel></MaintCarousel>
                 
                  </article>     
            </div>
            <div className='service-container odd-service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Training farmers on entrepreneurial/business skills</h3>
                  {/* <p>We are trusted in bridge construction in Uganda. Our expertise is in delivery of innovative and quality solutions for bridges. Over the years, we have successfully executed several projects in different parts of Uganda.</p> */}
                        {/* <ul>
                <li><strong>Client:</strong> Musana Empowerment Centre</li> 
<li><strong>Location:</strong> Kaliro</li>
<li><strong>Period:</strong>  February 2023 - to date</li>
<li><strong>Value:</strong>  Ugx 500,000,000</li>
</ul> */}
                  </article>

                  <article className='service-slides'>
                    <EmergencyCarousel></EmergencyCarousel>
                 
                  </article>     
            </div>

           

            <div className='service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Mainstreaming gender and peace development positive concepts among smallholder cocoa farmers</h3>
                
                {/* <p>We provide our clients with exceptional, full service land surveying services and solutions. An accurate, forward looking, detailed land survey, conducted by experienced and knowledgeable professionals, is essential to the success and efficiency of planning, design, and site development.</p>
                  <p>If you’re a current or future homeowner, there are many of our services you can take advantage of. From the survey you need when you buy a new house, to locating your property corners so that you can build that new fence, we can serve you.</p>
                  <p>Are you planning a commercial project? We are experienced in offices, retail and residential developments and many others, from boundary surveys to annexation, subdividing, site design and construction layout.</p> */}
                  </article>

                  <article className='service-slides'>
                    <BuildingsCarousel></BuildingsCarousel>
                 
                  </article>     
            </div>


           

            
           </div>

    </section>
    </>
  )
}

export default Services