import Carousel from 'react-bootstrap/Carousel';

function Residential1() {
  return (
    <Carousel indicators={false} className='goals-carousel concrete-carousel'>
      
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/16.jpeg"
          alt="Second slide"
        />   
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/17.jpeg"
          alt="Second slide"
        />   
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/18.jpeg"
          alt="Second slide"
        />   
      </Carousel.Item>  
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/19.jpeg"
          alt="Second slide"
        />   
      </Carousel.Item> 
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/20.jpeg"
          alt="Second slide"
        />   
      </Carousel.Item> 
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/21.jpeg"
          alt="Second slide"
        />   
      </Carousel.Item> 
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/22.jpeg"
          alt="Second slide"
        />   
      </Carousel.Item> 
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/23.jpeg"
          alt="Second slide"
        />   
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/24.jpeg"
          alt="Second slide"
        />   
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/25.jpeg"
          alt="Second slide"
        />   
      </Carousel.Item>  
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/26.jpeg"
          alt="Second slide"
        />   
      </Carousel.Item> 
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/27.jpeg"
          alt="Second slide"
        />   
      </Carousel.Item> 
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/28.jpeg"
          alt="Second slide"
        />   
      </Carousel.Item> 
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/29.jpeg"
          alt="Second slide"
        />   
      </Carousel.Item> 
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/30.jpeg"
          alt="Second slide"
        />   
      </Carousel.Item>  
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/31.jpeg"
          alt="Second slide"
        />   
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/32.jpeg"
          alt="Second slide"
        />   
      </Carousel.Item> 
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/33.jpeg"
          alt="Second slide"
        />   
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/34.jpeg"
          alt="Second slide"
        />   
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/35.jpeg"
          alt="Second slide"
        />   
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/36.jpeg"
          alt="Second slide"
        />   
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/37.jpeg"
          alt="Second slide"
        />   
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/38.jpeg"
          alt="Second slide"
        />   
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/39.jpeg"
          alt="Second slide"
        />   
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/40.jpeg"
          alt="Second slide"
        />   
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/41.jpeg"
          alt="Second slide"
        />   
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/42.jpeg"
          alt="Second slide"
        />   
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/43.jpeg"
          alt="Second slide"
        />   
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/44.jpeg"
          alt="Second slide"
        />   
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/45.jpeg"
          alt="Second slide"
        />   
      </Carousel.Item> 
      
    </Carousel>
  );
}

export default Residential1;