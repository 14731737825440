import React,{useEffect} from 'react'
import CarouselFade from '../components/HomeCarousel';
import PowerCarousel from '../components/PowerCarousel';
import TelecomCarousel from '../components/TelecomCarousel';
import RoadCarousel from '../components/RoadCarousel';
import SupportCarousel from '../components/SupportCarousel';
import MaintCarousel from '../components/Maintenance';
import EmergencyCarousel from '../components/Emergency';
import BuildingsCarousel from '../components/BuildingsCarousel';
import Plumbing from '../components/plumbing';
import {Link} from 'react-router-dom';
import GoalsCarousel from '../components/GoalsCarousel';
import {FaQuoteLeft, FaArrowAltCircleRight,FaCheckCircle} from 'react-icons/fa';
import {BsBarChartFill} from 'react-icons/bs'
import VisionCarousel from '../components/Vision Carousel';
let scrollFunc = () =>{
window.scrollTo({
   top:0,
behavior: "smooth",
});

}

const Home = () => {

useEffect(()=>{
document.title = 'Bundikaghoro Cocoa Farmers’ Cooperative Society Limited | Quality Our Priority';

},[]);


  return (
    <>
    <section>
      <CarouselFade></CarouselFade>
    </section>
     {/* <section className='section-cover who-we-are-cover' >
      <h2 className="home-h2 wwa-h2">About Us</h2>
          <div className='content-container vm-container'>
                 <div className='vision-mission-content vision-mission-content-a'>
                    <article className='vision-mission-slides'>
                          <VisionCarousel></VisionCarousel>

                    </article>

                    <article className='vision-mission-info vision-mission-info-a'>
                          <h3 className='title-h3'>Our Vision</h3>
                          
                           <div className='vm-line'></div>
                          <p>
                            Our business brings advanced telemetric (GPS tracking combined with wireless communication) products to the consumer market. Our product allows consumers to track the location and manage their vehicle with their mobile phone/computers and be notified when unexpected events such as a hijacking, accident or break-in occur.</p>
                            <p>  Our product is positioned as the next generation car alarm - silent with features that are useful every day, not just when a theft is attempted. We also support personal tracking devices such as GPS enabled phones to further improve the "peace of mind" of our customers. We will also be adding support for other wireless networks to improve the coverage of our products.
</p>
                          
                          <h3 className='title-h3'>Advantages of our solutions</h3>
                          <div className='vm-line'></div>
                          <p>
                          Our system has been proven to be efficient and highly competitive. It assists our clients achieve the following goals:
</p>
<ul>
<li>Cut operational costs.</li>
<li>Increase revenue.</li>
<li>Monitor and control the movement of the vehicle.</li>
<li>Save lives.</li>
<li>Fuel control.</li>

</ul>


                    </article>

                    


                 </div>

          </div>
    </section> */}
     {/* <section className='home-quote' style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("./images/9.jpg")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundAttachment:'fixed',
    padding:'4rem 0'

    }}>
          <div className='quote-content'>
            <h2 className='quote-h3'>Why Choose Us?</h2>
            <div className='pledge-line'></div>
         <p> <span className='quote-cover'><FaQuoteLeft></FaQuoteLeft></span><strong>Wanway Solutions Ltd</strong> is one of the leaders in tracking services and is committed to ensuring that you are always aware of the location of your asset. You monitor it yourself and therefore you can’t lose it!

</p>
<p>We provide excellent customer care services and follow-up on our customers through maintenance and regular upgrades on our systems.

</p>
        </div>
    </section> */}
    <section className='section-cover bg-cover'>
        <div className='content-container '>
          <h2 className="home-h2">Background</h2>
          <div className='line1'>
                    {/* <div className='line2'></div> */}
          </div>

                 <p className='home-p'><strong>Bundikaghoro Cocoa Farmers’ Cooperative Society Limited (BCFCSL)</strong> was formed by Cocoa Farmers with the aim of getting a platform for better price negotiations and to collectively address problems facing the Cocoa sector in the area. The Cooperative Society is found in Bundibugyo district, an area popularly known for cocoa growing in Uganda. The district is found in the Western Part of Uganda in East Africa and has a population of approximately 380,000 people. 
</p>
<p className='home-p'>
Since the cocoa crop was introduced in Bundibugyo district in 1950s, private companies such as Esco Uganda, OLAM, Green Organic Watch, ICAM, Ugaden and Three Farmers Investments have been engaged in buying cocoa beans from the farmers. As more companies and individual cocoa traders penetrated the cocoa market in this area, competition became stiff and some companies such as Green organic Watch, which had established a direct working relationship with the farmers, closed shop in 2010. This was followed by Three Farmers’ Investments who exited at the beginning of September 2015. As a result, farmers realized the need to come together to genuinely address their own problems and begin marketing their cocoa so as to realize premium prices and improve their livelihoods. 

</p>


<p className='home-p'>
By 2019, a large section of the cocoa farmers had mobilized themselves and formed Bundikaghoro Cooperative Society Limited that would address their challenges of limited access to stable cocoa markets. By September 2021 the 30 farmers formed a Cooperative Society named as “Bundikaghoro Cocoa Farmers’ Cooperative Society Limited” (BCFCSL) - registered by the Government of Uganda under the Ministry of Industry, Trade and Cooperatives. Registration Certificate No. 12024/RCS. At the beginning of March 2021, the Cooperative Society worked negotiated an internal market within Bundibugyo and also with Equator Chocolate, Moonbean chocolate all from Kampala, with a common goal and interest of developing an independent cocoa business for the benefit of farmers. </p>

<p className='home-p'>The Cooperative Society has over 3075 farmers who are further clustered 6 farmer groups each having an average of farmers. Currently, the Cooperative Society receives individual farmers and groups that have interest in joining us every week (we are still putting them on halt). With those farmers so far registered, they have an average of approximately 6 dependents each making it to 2,250 for the entire farmer already.</p>
 
        </div>

    </section>
  <section className='home-quote' style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.663), rgba(0, 0, 0, 0.663)), url("./images/4a.jpeg")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundAttachment:'fixed',
    padding:'3rem 0'

    }}>
          <div className='quote-content'>
            <h2 className='quote-h3'>Our Aim</h2>
            <div className='pledge-line'></div>
         <p> <span className='quote-cover'><FaQuoteLeft></FaQuoteLeft></span>Our aim is to use our crazy good cocoa taste and flavour to contribute to the growth of local economy and to help our 3075 smallholder farmers increase their household wealth and well-being.

</p>
        </div>
    </section>
    
 {/* <section className='section-cover'>
       <div className='content-container goals-container'>
           <article className='goals-text'>
              <h2 className='home-h2 home-service-h2 goals-h2'>Our Goals</h2>
              <div className='goals-list goals-list1'>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>More and more of our clients are turning to us for our distinctive ability to implement
innovative project management techniques and to serve as a reliable provider of
knowledge-driven solutions for their complex construction and Supply projects.
</p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description goal-description1'><strong> REJO ENGINEERING LIMITED</strong> began as a general works contractor in 2014. Over the
years, the company has undertaken many challenging projects and accumulated skills, know-how and
experiences in delivering building projects, water projects, road and bridge construction
projects and build solutions, project management services, building trades and related
engineering works.
<br></br> <br></br>
Today, <strong>REJO ENGINEERING LIMITED</strong> takes on the role of main contractor for small,
medium and large size construction projects and performs project management services to
coordinate specialist trades for industrial/commercial projects. We also provide design inputs
and engineering solutions as value-add services to our clients.
<br></br> <br></br>
We take pride in our delivery, thus our clients can always be assured that only the most experienced and qualified people are serving them, all the time.</p>
                     </div>


                </div>
                   

           </article>

           <article className='goals-slides'>
               <GoalsCarousel></GoalsCarousel>
               

           </article>

       </div>

  </section> */}

 
 {/* <section className='section-cover section-cover2' style={{ backgroundImage: `linear-gradient(  rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.863)), url("./images/4.jpg")`,backgroundAttachment:'fixed',backgroundSize:'cover', backgroundPosition:'center'
    }}>
       <div className='content-container goals-container'>
           <article className='goals-text goals-text1 goals-text2 goals-text3'>
              <h2 className='home-h2 home-service-h2 goals-h2'>Our Core Values</h2>
              <h3 className='title-h3'>Our Objectives</h3>
              <div className='vm-line'></div>
              <div className='goals-list goals-list1'>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCheckCircle></FaCheckCircle>
                          </span>
                          <p className='goal-description goal-description-a'> To provide the most efficient installation, repair
and maintenance solutions for clients’
dependable work continuity.
</p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCheckCircle></FaCheckCircle>
                          </span>
                          <p className='goal-description goal-description-a'>To ensure timely, correct and best quality
delivery of services and supplies.</p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCheckCircle></FaCheckCircle>
                          </span>
                          <p className='goal-description goal-description-a'>To grow and maintain trusted relationships
with our clients, partners and government.</p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCheckCircle></FaCheckCircle>
                          </span>
                          <p className='goal-description goal-description-a'>To foster client up-to-date market awareness.

</p>
                     </div>
                    
                </div>
                   

           </article>

           




       </div>

  </section> */}

  
   {/* <section className='section-cover section-cover2' style={{ backgroundImage: `linear-gradient(  rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("./images/11.jpg")`,backgroundAttachment:'fixed',backgroundSize:'cover', backgroundPosition:'center'
    }}>
       <div className='content-container goals-container'>
           <article className='goals-text goals-text1 goals-text2 goals-text3'>
              <h2 className='home-h2 home-service-h2 goals-h2'>Our Core Values</h2>
              <h3 className='title-h3'>Our Clientele</h3>
              <div className='vm-line'></div>
              <div className='goals-list goals-list-a'>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>Individuals. 
</p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>Tour & Travel Operators.</p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>Transportation & Haulage Companies.</p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'> Taxi Operators. </p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>Delivery & Distribution Companies. </p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>Leasing & Car Rental Companies.</p>
                     </div>

                </div>
                   

           </article>

       </div>
                    
  </section> */}

  {/* <section className='section-cover clients-cover' >
          <h2 className="home-h2">Our Clients</h2>
           <div className='line1'> 
           <div className='line2'></div>
           </div>
          <p className='clients-para'></p>
        <div className='content-container clients'>

           <article className='client'>
                      <img src='./images/1.png' alt='client' className='client-image'></img>
           </article>

            <article className='client'>
                      <img src='./images/2.jpg' alt='client' className='client-image'></img>
           </article>

            <article className='client'>
                      <img src='./images/3.png' alt='client' className='client-image'></img>
           </article>
           <article className='client'>
                      <img src='./images/4.png' alt='client' className='client-image'></img>
           </article>

            <article className='client'>
                      <img src='./images/5.png' alt='client' className='client-image'></img>
           </article>

          </div> 
    </section> */}
   
    
  
    </>
  )
}

export default Home